import { Routes } from '@angular/router';

import { redirectWithQueryParams } from '../../guards/redirect.guard';
import { TabsPageGuard } from '../../guards/tabs-page.guard';
import { EmptyPage } from '../empty/empty.page';

import { ExchangeAcceptedPage } from './exchange-accepted/exchange-accepted.page';
import { ExchangeAvailablePage } from './exchange-available/exchange-available.page';
import { ExchangeHistoryDetailPage } from './exchange-history-detail/exchange-history-detail.page';
import { ExchangeRootPage } from './exchange-root/exchange-root.page';
import { ExchangeShippedPage } from './exchange-shipped/exchange-shipped.page';
import { MobileShipmentPage } from './mobile-shipment/mobile-shipment.page';

const exchangeRoutes: Routes = [
  {
    path: '',
    canActivate: [TabsPageGuard],
    canDeactivate: [TabsPageGuard],
    children: [
      {
        path: 'top',
        canActivate: [redirectWithQueryParams('/exchange')],
        component: EmptyPage,
      },
      {
        path: '',
        component: ExchangeRootPage,
        children: [
          {
            path: 'available',
            component: ExchangeAvailablePage,
          },
          {
            path: 'accepted',
            component: ExchangeAcceptedPage,
          },
          {
            path: 'shipped',
            component: ExchangeShippedPage,
          },
          {
            path: '',
            canActivate: [redirectWithQueryParams('/exchange/available')],
            component: EmptyPage,
          },
        ],
      },
      {
        path: 'history',
        children: [
          {
            path: '',
            redirectTo: '/exchange/available',
            pathMatch: 'full',
          },
          {
            path: ':shipmentId',
            component: ExchangeHistoryDetailPage,
          },
        ],
      },
      {
        path: 'mobile_shipment',
        component: MobileShipmentPage,
      },
    ],
  },
];

export default exchangeRoutes;
