<ng-container *slLoading="!loading">
  @if (shipment$ | async; as shipment) {
    <sl-breadcrumb>
      <sl-breadcrumb-item path="/exchange">{{ 'shared.breadcrumbs.exchange' | locale }}</sl-breadcrumb-item>
      @if (shipment?.status === 'shipped') {
        <sl-breadcrumb-item path="/exchange/shipped">{{ 'shared.exchange.shipped' | locale }}</sl-breadcrumb-item>
      } @else {
        <sl-breadcrumb-item path="/exchange/accepted">{{ 'shared.exchange.accepted' | locale }}</sl-breadcrumb-item>
      }
      <sl-breadcrumb-item>{{ shipment?.shipmentNumber }}</sl-breadcrumb-item>
    </sl-breadcrumb>

    <sl-layout size="small" class="content">
      <sl-shipment-item [shipment]="shipment" class="shipment"></sl-shipment-item>

      <div class="info">
        <div class="info-item">
          <div class="info-item-heading">{{ 'pages.exchangeHistoryDetail.shippingFee' | locale }}</div>
          <div class="info-item-body">{{ shipment.amount | localCurrency: { showTaxInfo: true } }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-heading">{{ 'pages.exchangeHistoryDetail.paymentMethodForShippingFee' | locale }}</div>
          <div class="info-item-body">{{ shipment.paymentable | paymentMethodName }}</div>
        </div>
      </div>

      <div class="tracking status-{{ shipment.status }}">
        <div class="tracking-item destination">
          <div class="tracking-icon">
            <sl-icon name="marker"></sl-icon>
          </div>
          <div class="tracking-body">
            <div class="address">
              <div class="address-name">{{ 'shared.address.name' | locale: { name: shipment?.name } }}</div>
              <div class="address-body">
                <div class="address-postal-code">
                  {{ 'shared.address.postalCode' | locale: { postalCode: shipment?.postalCode } }}
                </div>
                <div class="address-text">{{ shipment | addressText: { hideCountry: isSingleCountry$ | async } }}</div>
              </div>
              <div class="address-additional-data">
                <div class="email">{{ shipment.email }}</div>
                <div class="phone">{{ shipment.phoneNumber | phoneNumber }}</div>
              </div>
              <div class="address-update">
                @if (shipment.addressChangeable) {
                  <sl-button class="address-update-button" (click)="updateAddress(shipment.id)">{{
                    'pages.exchangeHistoryDetail.updateAddress' | locale
                  }}</sl-button>
                } @else {
                  <div class="address-update-expired">
                    <sl-icon name="notice" class="address-update-expired-icon"></sl-icon>
                    <div
                      class="address-update-expired-text"
                      [innerHTML]="'pages.exchangeHistoryDetail.updateAddressExpired' | locale"
                    ></div>
                  </div>
                }
              </div>
            </div>
            @if (shipment?.status === 'shipped') {
              <div class="carrier">
                @if (splitTrackingCode(shipment?.shippingTrackingNumber); as codes) {
                  @for (code of codes; track $index) {
                    <div class="carrier-tracking" (click)="copyTrackingCode(code)">
                      <div class="carrier-tracking-body">
                        <div class="carrier-name">{{ shipment?.shippingCompany }}</div>
                        <div class="carrier-tracking-code">{{ code }}</div>
                      </div>
                      <div class="copy">
                        <sl-icon class="icon" name="copy"></sl-icon>
                        <div class="text">
                          {{ 'pages.exchangeHistoryDetail.copyTrackingNumberButtonText' | locale }}
                        </div>
                      </div>
                    </div>
                  }
                }
              </div>
            }
          </div>
        </div>

        <div class="tracking-item shipped">
          <div class="tracking-icon">
            <sl-icon name="shipped"></sl-icon>
          </div>
          <div class="tracking-body">
            @if (shipment.status === 'shipped') {
              <div class="tracking-heading">{{ 'shipped' | shipmentStatus }}</div>
              @if (shipment?.shippedDate) {
                <div class="tracking-date">
                  {{ shipment.shippedDate | localDate: 'mediumDate' }}
                </div>
              }
            } @else {
              <div class="tracking-heading">{{ 'shared.exchange.scheduled' | locale }}</div>
              @if (shipment?.shippingPeriod?.shippingSchedule) {
                <div class="tracking-date">
                  {{ shipment?.shippingPeriod?.shippingSchedule }}
                </div>
              }
            }
          </div>
        </div>

        <div class="tracking-item accepted">
          <div class="tracking-icon">
            <sl-icon name="accepted"></sl-icon>
          </div>
          <div class="tracking-body">
            <div class="tracking-heading">{{ 'accepted' | shipmentStatus }}</div>
            <div class="tracking-date">{{ shipment.createdAt | localDate: 'medium' }}</div>
          </div>
        </div>
      </div>

      <div class="body">
        <sl-shipment-loots [machines]="shipment?.machines"></sl-shipment-loots>
      </div>

      <div class="back">
        <sl-button type="stroked" slBackButton [defaultHref]="'/exchange/' + shipment?.status">{{
          'shared.navigation.back' | locale
        }}</sl-button>
      </div>
    </sl-layout>
  }
</ng-container>
