import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NgClass } from '@angular/common';
import { Image } from '../../../models/image';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';

@Component({
  selector: 'sl-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ImageSizePipe],
})
export class ImageGridComponent implements OnInit {
  _images: Image[];
  imagesCount: number;
  containerClass: string;
  overflowLabel: string;
  @Input()
  set images(value: Image[]) {
    this._images = value.slice(0, 4);
    this.imagesCount = value.length;
    this.containerClass = `count-${Math.min(this.imagesCount, 4)}`;
    this.overflowLabel = this.imagesCount - 3 > 99 ? '99+' : `+${this.imagesCount - 3}`;
  }

  constructor() {}

  ngOnInit() {}
}
