<div class="container" [ngClass]="containerClass">
  @for (image of _images; track image.id) {
    <div class="image">
      <img [src]="image | imageSize: 'medium'" />
    </div>
  }
</div>
@if (imagesCount > 4) {
  <div class="overflow-label">{{ overflowLabel }}</div>
}
