import { Pipe, PipeTransform, inject } from '@angular/core';

import { ShipmentStatus } from '../models/shipment';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'shipmentStatus',
  standalone: true,
})
export class ShipmentStatusPipe implements PipeTransform {
  private localeService = inject(LocaleService);

  transform(value: ShipmentStatus): string {
    switch (value) {
      case 'accepted':
      case 'shipped':
        return this.localeService.translate(`shared.shipmentStatus.${value}`);
      default:
        return '-';
    }
  }
}
