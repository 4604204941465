import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AsyncPipe } from '@angular/common';
import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { ShipmentItemComponent } from '../../../components/shipment/shipment-item/shipment-item.component';
import { ShipmentLootsComponent } from '../../../components/shipment/shipment-loots/shipment-loots.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Shipment } from '../../../models/shipment';
import { AddressTextPipe } from '../../../pipes/address-text.pipe';
import { LocalCurrencyPipe } from '../../../pipes/local-currency.pipe';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { PaymentMethodNamePipe } from '../../../pipes/payment-method-name.pipe';
import { PhoneNumberPipe } from '../../../pipes/phone-number.pipe';
import { ShipmentStatusPipe } from '../../../pipes/shipment-status.pipe';
import { AlertService } from '../../../services/alert.service';
import { CountryService } from '../../../services/country.service';
import { LocaleService } from '../../../services/locale.service';
import { ShipmentService } from '../../../services/shipment.service';
import { ShippingRequestService } from '../../../services/shipping-request.service';

@Component({
  selector: 'page-exchange-history-detail',
  templateUrl: './exchange-history-detail.page.html',
  styleUrls: ['./exchange-history-detail.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    ShipmentItemComponent,
    IconComponent,
    ButtonComponent,
    ShipmentLootsComponent,
    BackButtonDirective,
    AsyncPipe,
    AddressTextPipe,
    LocalCurrencyPipe,
    LocalDatePipe,
    LocalePipe,
    PaymentMethodNamePipe,
    PhoneNumberPipe,
    ShipmentStatusPipe,
  ],
})
export class ExchangeHistoryDetailPage implements OnInit {
  shipmentId: string;
  shipment$: Observable<Shipment>;
  isSingleCountry$: Observable<boolean>;
  loading = true;

  private readonly route = inject(ActivatedRoute);
  private readonly clipboard = inject(Clipboard);
  private readonly alertService = inject(AlertService);
  private readonly shipmentService = inject(ShipmentService);
  private readonly shippingRequestService = inject(ShippingRequestService);
  private readonly localeService = inject(LocaleService);
  private readonly countryService = inject(CountryService);

  constructor() {
    this.shipmentId = this.route.snapshot.paramMap.get('shipmentId');
    this.shipment$ = this.shipmentService.selectShipment(this.shipmentId);
    this.isSingleCountry$ = this.countryService.isSingleCountry$;
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentService.fetchShipment(this.shipmentId).subscribe(() => {
      this.loading = false;
    });
    this.countryService.fetchCountries().subscribe();
  }

  copyTrackingCode(code: string) {
    const trimmedCode = code?.trim();
    if (trimmedCode) {
      const result = this.clipboard.copy(trimmedCode);
      if (result) {
        const message = this.localeService.translate('pages.exchangeHistoryDetail.copyTrackingNumberResultMessage');
        this.alertService.showToast(message);
      }
    }
  }

  splitTrackingCode(code: string): string[] {
    return code?.split(',')?.map((c) => c.trim());
  }

  updateAddress(shipmentId: string) {
    this.shippingRequestService.showUpdateShipmentAddressModal(shipmentId);
  }
}
