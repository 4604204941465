<div mat-dialog-content slLayout>
  <div class="checkouts">
    @for (checkout of data?.shippingPeriod?.gachaCheckOuts; track checkout.id) {
      <a
        sl-lottery-checkout
        class="checkouts-item"
        [checkout]="checkout"
        slExternalLink="/mypage/history/{{ checkout?.id }}"
      ></a>
    }
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ 'shared.modal.defaultDismissText' | locale }}</button>
  <button mat-flat-button color="primary" (click)="showPreview()">
    {{ 'components.shippingPeriodDetailModal.exchange' | locale }}
  </button>
</div>
