import { Pipe, PipeTransform, inject } from '@angular/core';

import { SLASHKIT_LOCALIZE_OPTION, SlashkitLocalizeOption } from '../models/locale';

@Pipe({
  name: 'addressText',
  standalone: true,
})
export class AddressTextPipe implements PipeTransform {
  private localizeOption = inject<SlashkitLocalizeOption>(SLASHKIT_LOCALIZE_OPTION);

  transform<
    T extends {
      countryName: string;
      regionName: string;
      municipality: string;
      street: string;
      building: string;
    },
  >(value: T, { hideCountry }: { hideCountry?: boolean } = {}): string {
    return this.localizeOption.addressOrder
      .map((name) => {
        switch (name) {
          case 'country':
            return hideCountry ? null : value.countryName;
          case 'prefecture':
            return value.regionName;
          case 'city':
            return value.municipality;
          case 'street':
            return value.street;
          case 'building':
            return value.building;
          default:
            return null;
        }
      })
      .filter((v) => v)
      .join(' ');
  }
}
