<sl-exchange-steps mat-dialog-title [currentIndex]="0"></sl-exchange-steps>
<div mat-dialog-content slLayout>
  <div class="items">
    <sl-shipment-loots [machines]="data?.shipmentPreview?.machines"></sl-shipment-loots>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ 'shared.modal.defaultDismissText' | locale }}</button>
  <button mat-flat-button color="primary" (click)="showDeliveryRequest()">
    {{ 'components.shippingPeriodPreviewModal.shipmentRequest' | locale }}
  </button>
</div>
