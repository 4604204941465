import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';

import { LayoutDirective } from '../../../directives/layout.directive';
import { ShipmentLotteryMachine } from '../../../models/shipment';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { SecretImagePipe } from '../../../pipes/secret-image.pipe';
import { LotteryPrizeComponent } from '../../lottery/lottery-prize/lottery-prize.component';

@Component({
  selector: 'sl-shipment-loots',
  templateUrl: './shipment-loots.component.html',
  styleUrls: ['./shipment-loots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, PhotoGalleryModule, LotteryPrizeComponent, ImageSizePipe, SecretImagePipe],
})
export class ShipmentLootsComponent implements OnInit {
  @Input() machines: ShipmentLotteryMachine[];

  constructor() {}

  ngOnInit() {}
}
