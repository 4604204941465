<ng-container *slLoading="!loading">
  @if (shipments$ | async; as shipments) {
    @if (shipments?.length) {
      <sl-layout size="small" class="content" infiniteScroll (scrolled)="onScroll()">
        @for (shipment of shipments; track shipment.id) {
          <sl-shipment-item
            class="shipping"
            [shipment]="shipment"
            showButton
            routerLink="/exchange/history/{{ shipment?.id }}"
          >
          </sl-shipment-item>
        }
      </sl-layout>
    } @else {
      <sl-empty>{{ 'pages.exchangeShipped.emptyShippedProducts' | locale }}</sl-empty>
    }
  }
</ng-container>
