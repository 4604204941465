<ng-container *slLoading="!loading">
  @if (shippingPeriod$ | async; as shippingPeriods) {
    @if (shippingPeriods?.length) {
      <sl-layout size="small" class="content">
        <div class="note">{{ 'pages.exchangeAvailable.cautionMessage' | locale }}</div>
        @for (shippingPeriod of shippingPeriods; track shippingPeriod.id) {
          <div class="shipping">
            <div class="shipping-data">
              <div class="shipping-thumbnail">
                <sl-image-grid
                  class="shipping-thumbnail-image"
                  [images]="getMachineImages(shippingPeriod)"
                ></sl-image-grid>
              </div>
              <div class="shipping-data-body">
                <div class="shipping-summary">
                  <div class="quantity">
                    {{ 'pages.exchangeAvailable.quantity' | locale: { quantity: shippingPeriod?.quantity | number } }}
                  </div>
                  <div class="shipping-summary-bottom">
                    <a class="link" (click)="showPreview(shippingPeriod?.id)">
                      <sl-icon class="link-icon" name="arrow"></sl-icon>
                      <div class="link-text">{{ 'pages.exchangeAvailable.checkProduct' | locale }}</div>
                    </a>
                  </div>
                </div>
                <div class="shipping-info">
                  <div class="shipping-info-item">
                    <div class="shipping-info-item-heading">
                      {{ 'pages.exchangeAvailable.estimatedShipping' | locale }}
                    </div>
                    <div class="shipping-info-item-body">{{ shippingPeriod?.shippingSchedule }}</div>
                  </div>
                  <div class="shipping-info-item">
                    <div class="shipping-info-item-heading">
                      {{ 'pages.exchangeAvailable.exchangeExpires' | locale }}
                    </div>
                    <div class="shipping-info-item-body">
                      {{
                        'pages.exchangeAvailable.exchangeExpiresBody'
                          | locale: { earliestExpires: shippingPeriod?.earliestExchangeDueAt | dateFromNow }
                      }}
                    </div>
                    <div class="shipping-info-item-footer">
                      <a class="link" (click)="showDetail(shippingPeriod?.id)">
                        <sl-icon class="link-icon" name="arrow"></sl-icon>
                        <div class="link-text">{{ 'pages.exchangeAvailable.checkExchangeExpires' | locale }}</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="shipping-action">
              <sl-button (click)="showPreview(shippingPeriod?.id)">{{
                'pages.exchangeAvailable.exchangeButtonText' | locale
              }}</sl-button>
            </div>
          </div>
        }
      </sl-layout>
    } @else {
      <sl-empty>{{ 'pages.exchangeAvailable.emptyExchangeProductMessage' | locale }}</sl-empty>
    }
  }
</ng-container>
