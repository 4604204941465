import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { ExternalLinkDirective } from '../../../directives/external-link.directive';
import { LayoutDirective } from '../../../directives/layout.directive';
import { ShippingPeriod } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LotteryCheckoutComponent } from '../../lottery/lottery-checkout/lottery-checkout.component';

export interface ShippingPeriodDetailModalConfig {
  shippingPeriod: ShippingPeriod;
}

@Component({
  selector: 'sl-shipping-period-detail-modal',
  templateUrl: './shipping-period-detail-modal.component.html',
  styleUrls: ['./shipping-period-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    LayoutDirective,
    LotteryCheckoutComponent,
    ExternalLinkDirective,
    MatDialogActions,
    MatButton,
    LocalePipe,
  ],
})
export class ShippingPeriodDetailModalComponent implements OnInit {
  readonly data = inject<ShippingPeriodDetailModalConfig>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject<MatDialogRef<ShippingPeriodDetailModalComponent>>(MatDialogRef);

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  showPreview() {
    this.dialogRef.close({ showPreview: true });
  }
}
