<sl-exchange-steps mat-dialog-title [currentIndex]="1"></sl-exchange-steps>
<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <div mat-dialog-content slLayout>
      <div class="overview">
        <div class="overview-item">
          <div class="label">{{ 'components.deliveryRequestModal.productCountsLabel' | locale }}</div>
          <div class="value">
            <div class="num">{{ shipmentPreview?.quantity | number }}</div>
            <div class="unit">{{ 'components.deliveryRequestModal.productCountUnit' | locale }}</div>
          </div>
        </div>
        <div class="overview-item">
          <div class="label">{{ 'components.deliveryRequestModal.shipmentFeeLabel' | locale }}</div>
          <div
            class="value"
            [innerHTML]="shipmentPreview?.feePrice | localCurrency: { renderedHtml: true } | sanitize | safeHtml"
          ></div>
        </div>
        @if (shipmentPreview?.specificFeePrice; as specificFeePrice) {
          <div class="overview-item">
            <div class="label">{{ 'components.deliveryRequestModal.shipmentAdditionalFeeLabel' | locale }}</div>
            <div
              class="value"
              [innerHTML]="specificFeePrice | localCurrency: { renderedHtml: true } | sanitize | safeHtml"
            ></div>
          </div>
        }
      </div>

      @if (shipmentPreview?.amount > 0) {
        <div class="list" [formGroup]="form">
          <div class="list-header">{{ 'components.deliveryRequestModal.paymentMethodLabel' | locale }}</div>
          <div class="list-payment">
            <sl-select-payment [card]="state?.card" formControlName="paymentMethod"></sl-select-payment>
          </div>
        </div>
      }

      <div class="list">
        <div class="list-header">
          <div class="text">{{ 'components.deliveryRequestModal.shippingAddressLabel' | locale }}</div>
        </div>
        @if (isShipmentPreviewError(shipmentPreview)) {
          <div class="list-error">
            {{ shipmentPreview?.error?.displayMessages }}
          </div>
        }
        <div class="shipment">
          <mat-radio-button name="shippingAddress" [checked]="true" labelPosition="before" class="shipment-item">
            <div class="shipment-item-body">
              <div class="address">
                <div class="postal-code">
                  {{ 'shared.address.postalCode' | locale: { postalCode: shipmentPreview?.postalCode } }}
                </div>
                <div class="address-text">
                  {{ shipmentPreview | addressText: { hideCountry: isSingleCountry$ | async } }}
                </div>
                <div class="name">
                  {{ 'shared.address.name' | locale: { name: shipmentPreview?.name } }}
                </div>
                <div class="address-additional-data">
                  <div class="email">{{ shipmentPreview?.email }}</div>
                  <div class="phone">{{ shipmentPreview?.phoneNumber | phoneNumber }}</div>
                </div>
              </div>
            </div>
          </mat-radio-button>
          <button mat-button class="shipment-item-new" (click)="openChangeShippingAddress()">
            <div class="shipment-item-new-content">
              <div class="shipment-item-new-label">
                {{ 'components.deliveryRequestModal.changeShippingAddress' | locale }}
              </div>
              <sl-icon class="shipment-item-new-icon" name="arrow"></sl-icon>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="showPreview()">{{ 'components.deliveryRequestModal.backToList' | locale }}</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="disableShippingRequest || shipmentButtonDisabled"
        (click)="requestShipment(shipmentPreview?.meta?.shipmentSessionId)"
      >
        {{ 'components.deliveryRequestModal.requestShipping' | locale }}
      </button>
    </div>
  }
</ng-container>
