import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ButtonComponent } from '../../../components/button/button.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { ImageGridComponent } from '../../../components/image/image-grid/image-grid.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Image } from '../../../models/image';
import { ShippingPeriod } from '../../../models/shipment';
import { DateFromNowPipe } from '../../../pipes/date-from-now.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ShipmentService } from '../../../services/shipment.service';
import { ShippingRequestService } from '../../../services/shipping-request.service';
import { ExchangeRootPage } from '../exchange-root/exchange-root.page';

@Component({
  selector: 'page-exchange-available',
  templateUrl: './exchange-available.page.html',
  styleUrls: ['./exchange-available.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    LayoutComponent,
    ImageGridComponent,
    IconComponent,
    ButtonComponent,
    EmptyComponent,
    AsyncPipe,
    DecimalPipe,
    DateFromNowPipe,
    LocalePipe,
  ],
})
export class ExchangeAvailablePage implements OnInit {
  shippingPeriod$: Observable<ShippingPeriod[]>;
  loading = true;

  private exchangeRootPage = inject(ExchangeRootPage, { optional: true });
  private shipmentService = inject(ShipmentService);
  private shippingRequestService = inject(ShippingRequestService);

  constructor() {
    this.shippingPeriod$ = this.shipmentService.shippingPeriods$;
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentService.fetchActiveShipments().subscribe(() => {
      this.loading = false;
    });
    if (this.exchangeRootPage) {
      this.exchangeRootPage.setCurrentTab('available');
    }
  }

  getMachineImages(shippingPeriod: ShippingPeriod): Image[] {
    return shippingPeriod.gachaCheckOuts.map((checkout) => checkout?.machine?.mainImage);
  }

  showDetail(shippingPeriodId: string) {
    this.shippingRequestService.showShippingPeriodDetailModal(shippingPeriodId);
  }

  showPreview(shippingPeriodId: string) {
    this.shippingRequestService.showShippingPeriodPreviewModal(shippingPeriodId);
  }
}
