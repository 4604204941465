import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { firstValueFrom, Observable } from 'rxjs';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatRadioButton } from '@angular/material/radio';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { LayoutDirective } from '../../../directives/layout.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Card } from '../../../models/payment';
import { SelectablePaymentMethod } from '../../../models/payment-method';
import { ShipmentPreview, ShipmentPreviewError } from '../../../models/shipment';
import { AddressTextPipe } from '../../../pipes/address-text.pipe';
import { LocalCurrencyPipe } from '../../../pipes/local-currency.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { PhoneNumberPipe } from '../../../pipes/phone-number.pipe';
import { SanitizePipe } from '../../../pipes/sanitize.pipe';
import { AlertService } from '../../../services/alert.service';
import { CountryService } from '../../../services/country.service';
import { LocaleService } from '../../../services/locale.service';
import { PaymentMethodService } from '../../../services/payment-method.service';
import { PaymentService } from '../../../services/payment.service';
import { ShipmentService } from '../../../services/shipment.service';
import { ShippingRequestService } from '../../../services/shipping-request.service';
import { UserDetailService } from '../../../services/user-detail.service';
import { combineStream } from '../../../util';
import { ExchangeStepsComponent } from '../../exchange/exchange-steps/exchange-steps.component';
import { IconComponent } from '../../icon/icon.component';
import { SelectPaymentComponent } from '../../payments/select-payment/select-payment.component';

export type DeliveryRequestModalState = {
  card: Card;
};

export interface DeliveryRequestConfig {
  shipmentPreview: ShipmentPreview;
}

export interface DeliveryRequestForm {
  paymentMethod: SelectablePaymentMethod;
}

@Component({
  selector: 'sl-delivery-request-modal',
  templateUrl: './delivery-request-modal.component.html',
  styleUrls: ['./delivery-request-modal.component.scss'],
  standalone: true,
  imports: [
    ExchangeStepsComponent,
    MatDialogTitle,
    LoadingDirective,
    CdkScrollable,
    MatDialogContent,
    LayoutDirective,
    FormsModule,
    ReactiveFormsModule,
    SelectPaymentComponent,
    MatRadioButton,
    MatButton,
    IconComponent,
    MatDialogActions,
    AsyncPipe,
    DecimalPipe,
    AddressTextPipe,
    LocalCurrencyPipe,
    LocalePipe,
    PhoneNumberPipe,
    SanitizePipe,
    SafeHtmlPipe,
  ],
})
export class DeliveryRequestModalComponent implements OnInit {
  readonly modalData = inject<DeliveryRequestConfig>(MAT_DIALOG_DATA);

  state$: Observable<DeliveryRequestModalState>;
  isSingleCountry$: Observable<boolean>;
  loading = true;
  form: FormGroup<{
    paymentMethod: FormControl<SelectablePaymentMethod>;
  }>;
  shipmentPreview: ShipmentPreview | ShipmentPreviewError;
  shipmentButtonDisabled = false;

  private readonly fb = inject(FormBuilder);
  private readonly paymentService = inject(PaymentService);
  private readonly paymentMethodService = inject(PaymentMethodService);
  private readonly shipmentService = inject(ShipmentService);
  private readonly shippingRequestService = inject(ShippingRequestService);
  private readonly alertService = inject(AlertService);
  private readonly dialogRef = inject<MatDialogRef<DeliveryRequestModalComponent>>(MatDialogRef);
  private readonly countryService = inject(CountryService);
  private readonly userDetailService = inject(UserDetailService);
  private readonly localeService = inject(LocaleService);

  constructor() {
    this.state$ = combineStream({
      card: this.paymentService.card$,
    });
    this.isSingleCountry$ = this.countryService.isSingleCountry$;
    this.form = this.fb.group({
      paymentMethod: new FormControl<SelectablePaymentMethod>(null, {
        nonNullable: false,
        validators: [Validators.required],
      }),
    });

    this.shipmentPreview = this.modalData?.shipmentPreview;
  }

  get disableShippingRequest() {
    if (this.isShipmentPreviewError(this.shipmentPreview)) {
      return !!this.shipmentPreview.error;
    } else {
      return false;
    }
  }

  ngOnInit() {
    if (this.shipmentPreview?.amount > 0) {
      this.paymentService.fetchCard().subscribe(async (card) => {
        this.loading = false;
        const defaultPaymentMethod = await this.paymentMethodService.getDefaultPaymentMethod(card);
        this.form.patchValue({ paymentMethod: defaultPaymentMethod }, { emitEvent: false });
      });
    } else {
      this.loading = false;
      this.form.patchValue({ paymentMethod: 'free' }, { emitEvent: false });
    }
    this.countryService.fetchCountries().subscribe();
  }

  isShipmentPreviewError(obj: ShipmentPreview | ShipmentPreviewError): obj is ShipmentPreviewError {
    return 'error' in obj;
  }

  showPreview() {
    this.dialogRef.close({ showPreview: true });
  }

  async openChangeShippingAddress() {
    const result = await this.userDetailService.showDeliveryInfoEditModal();
    if (result && this.shipmentPreview?.shippingPeriod?.id) {
      await this.alertService.showLoader(
        this.localeService.translate('components.deliveryRequestModal.updatingShippingAddressMessage'),
      );
      const shipmentPreview = await this.shippingRequestService.fetchShipmentPreview(
        this.shipmentPreview?.shippingPeriod?.id,
      );
      this.alertService.dismissLoader();
      if (shipmentPreview.isFailure()) {
        this.alertService.showErrorFromResponse(shipmentPreview.error);
      } else {
        this.shipmentPreview = shipmentPreview.value;
      }
    }
  }

  async requestShipment(shipmentSessionId: string) {
    if (this.form.invalid) {
      return;
    }

    if (this.form.value.paymentMethod === 'new_card') {
      this.loading = true;
      const status = await this.userDetailService.showPaymentEditModal().catch(() => {
        this.loading = false;
        return null as null;
      });
      if (status) {
        const card = await firstValueFrom(this.paymentService.fetchCard());
        if (card) {
          this.form.patchValue({ paymentMethod: 'registered_card' });
        }
      }
      this.loading = false;
      return;
    }

    if (this.form.value.paymentMethod !== 'free') {
      this.paymentMethodService.setDefaultPayment(this.form.value.paymentMethod);
    }

    this.shipmentButtonDisabled = true;
    const result = await this.shipmentService.requestShipment(shipmentSessionId, this.form.value.paymentMethod);
    this.shipmentButtonDisabled = false;
    if (result) {
      this.shipmentService.fetchActiveShipments().subscribe();
      this.dialogRef.close({ shipmentId: result?.id });
    }
  }
}
