import { Component, OnInit, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Observable, map } from 'rxjs';

import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LoginButtonsComponent } from '../../../components/login-buttons/login-buttons.component';
import { NavbarItemComponent } from '../../../components/navbar/navbar-item/navbar-item.component';
import { NavbarComponent } from '../../../components/navbar/navbar/navbar.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { SessionService } from '../../../services/auth/session.service';
import { ShipmentService } from '../../../services/shipment.service';
import { combineStream } from '../../../util';

export type TabType = 'available' | 'accepted' | 'shipped' | 'expired';

export type ExchangeRootState = {
  firebaseUser: User;
  hasUnshippedCheckout: boolean;
};

@Component({
  selector: 'page-exchange-root',
  templateUrl: './exchange-root.page.html',
  styleUrls: ['./exchange-root.page.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    LayoutComponent,
    NavbarComponent,
    FormsModule,
    NavbarItemComponent,
    RouterLink,
    RouterOutlet,
    LoginButtonsComponent,
    LoadingDirective,
    AsyncPipe,
    LocalePipe,
    SafeHtmlPipe,
  ],
})
export class ExchangeRootPage implements OnInit {
  currentTab: TabType = 'available';
  state$: Observable<ExchangeRootState>;

  private readonly shipmentService = inject(ShipmentService);
  private readonly sessionService = inject(SessionService);

  constructor() {
    this.state$ = combineStream({
      firebaseUser: this.sessionService.firebaseUser$,
      hasUnshippedCheckout: this.shipmentService.shippingPeriods$.pipe(
        map((shippingPeriods) => shippingPeriods?.length > 0),
      ),
    });
  }

  ngOnInit() {}

  async setCurrentTab(tab: TabType) {
    // ExpressionChangedAfterItHasBeenCheckedError回避の為に1サイクル待ってからプロパティを書き換える
    await Promise.resolve();
    this.currentTab = tab;
  }
}
