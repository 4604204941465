<sl-page-header>{{ 'shared.breadcrumbs.exchange' | locale }}</sl-page-header>

@if (state$ | async; as state) {
  @if (!state?.firebaseUser?.isAnonymous) {
    <sl-layout size="small" class="nav">
      <sl-navbar [(ngModel)]="currentTab">
        <sl-navbar-item value="available" routerLink="available" [notice]="state?.hasUnshippedCheckout">{{
          'shared.exchange.available' | locale
        }}</sl-navbar-item>
        <sl-navbar-item value="accepted" routerLink="accepted">{{
          'shared.exchange.accepted' | locale
        }}</sl-navbar-item>
        <sl-navbar-item value="shipped" routerLink="shipped">{{ 'shared.exchange.shipped' | locale }}</sl-navbar-item>
      </sl-navbar>
    </sl-layout>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  } @else {
    <sl-layout size="small" class="not-logged-in">
      <sl-login-buttons [showSignUpButton]="false">
        <div
          class="not-logged-in-message"
          [innerHTML]="'pages.exchangeRoot.loginRequiredMessage' | locale | safeHtml"
        ></div>
      </sl-login-buttons>
    </sl-layout>
  }
} @else {
  <!-- state$ に値が流れてこない場合用 (基本無い) -->
  <ng-container *slLoading="false"></ng-container>
}
