@for (lottery of machines; track lottery.id) {
  <div class="lottery" slLayout>
    <div class="lottery-info">
      <div class="lottery-name">{{ lottery?.name }}</div>
    </div>
    <div class="lottery-prizes" [photoGalleryGroup]="{ showHideOpacity: true, captionEl: true }">
      @for (loot of lottery?.shipmentLoots; track loot.id) {
        <div
          class="lottery-prize-item"
          [photoGallery]="loot?.prize | secretImage | imageSize: 'original'"
          [photoGalleryCaption]="loot?.prize?.name"
        >
          <sl-lottery-prize
            class="lottery-prize-body"
            [prize]="loot?.prize"
            [quantity]="loot.quantity"
          ></sl-lottery-prize>
        </div>
      }
      @for (loot of lottery?.shipmentBonusLoots; track loot.bonusPrize.id) {
        <div
          class="lottery-prize-item"
          [photoGallery]="loot?.bonusPrize | secretImage | imageSize: 'original'"
          [photoGalleryCaption]="loot?.bonusPrize?.name"
        >
          <sl-lottery-prize
            class="lottery-prize-body"
            [prize]="loot?.bonusPrize"
            [quantity]="loot.quantity"
          ></sl-lottery-prize>
        </div>
      }
    </div>
  </div>
}
