import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'sl-exchange-steps',
  templateUrl: './exchange-steps.component.html',
  styleUrls: ['./exchange-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ExchangeStepsComponent implements OnInit {
  @Input() currentIndex = 0;
  steps: string[];

  private localeService = inject(LocaleService);

  constructor() {
    this.steps = [
      this.localeService.translate('components.exchangeSteps.checkProduct'),
      this.localeService.translate('components.exchangeSteps.deliveryRequest'),
      this.localeService.translate('components.exchangeSteps.requestCompleted'),
    ];
  }

  ngOnInit() {}
}
