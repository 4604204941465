import { Component, inject, OnInit } from '@angular/core';
import { User as FirebaseUser } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, map, Observable, take } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { DeliveryCompleteModalComponent } from '../../../components/modals/delivery-complete-modal/delivery-complete-modal.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { MobilePaymentShipmentStatus } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ShipmentService } from '../../../services/shipment.service';
import { getFirebaseUser } from '../../../store/session';
import { AppState } from '../../../store/symbols';

@Component({
  selector: 'sl-mobile-shipment',
  templateUrl: './mobile-shipment.page.html',
  styleUrls: ['./mobile-shipment.page.scss'],
  standalone: true,
  imports: [LoadingDirective, ButtonComponent, RouterLink, LocalePipe],
})
export class MobileShipmentPage implements OnInit {
  loading = true;
  firebaseUser$: Observable<FirebaseUser>;
  shipmentStatus: MobilePaymentShipmentStatus;
  shipmentId: string;
  private readonly statusKey = 'status';
  private readonly shipmentIdKey = 'shipment_id';
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);
  private readonly store = inject<Store<AppState>>(Store);
  private readonly shipmentService = inject(ShipmentService);

  constructor() {
    this.shipmentStatus = this.route.snapshot.queryParamMap.get(this.statusKey) as MobilePaymentShipmentStatus;
    this.shipmentId = this.route.snapshot.queryParamMap.get(this.shipmentIdKey);
    this.firebaseUser$ = this.store.select(getFirebaseUser);
  }

  async ngOnInit() {
    if (this.shipmentStatus === 'failure') {
      await this.shipmentService.failedShipmentByMobilePayment();
      this.loading = false;
      return;
    } else if (this.shipmentStatus === 'canceled') {
      await this.shipmentService.onCancelMobilePayment();
      this.loading = false;
      return;
    }

    // 成功時
    const isAnonymous = await firstValueFrom(
      this.firebaseUser$.pipe(
        take(1),
        map((user) => user?.isAnonymous ?? true),
      ),
    );
    if (!isAnonymous) {
      const resp = await firstValueFrom(this.shipmentService.fetchShipment(this.shipmentId));
      if (resp) {
        this.loading = false;
        this.router.navigate(['/exchange/history', this.shipmentId], { replaceUrl: true });
      }
    } else {
      const dialogRef = this.dialog.open(DeliveryCompleteModalComponent, {
        data: {
          status: this.shipmentStatus,
          shipmentId: this.shipmentId,
        },
        disableClose: true,
        autoFocus: false,
      });
      await firstValueFrom(dialogRef.afterOpened());
      this.loading = false;
    }
  }
}
