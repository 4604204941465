import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Result, createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import { firstValueFrom, map, take } from 'rxjs';

import { ShipmentApi } from '../apis/shipment';
import { DeliveryInfoEditModalComponent } from '../components/modals/delivery-info-edit-modal/delivery-info-edit-modal.component';
import { DeliveryRequestModalComponent } from '../components/modals/delivery-request-modal/delivery-request-modal.component';
import { ShippingPeriodDetailModalComponent } from '../components/modals/shipping-period-detail-modal/shipping-period-detail-modal.component';
import { ShippingPeriodPreviewModalComponent } from '../components/modals/shipping-period-preview-modal/shipping-period-preview-modal.component';
import { ShipmentPreview, ShipmentPreviewError } from '../models/shipment';

import { AlertService } from './alert.service';
import { LocaleService } from './locale.service';
import { UserDetailService } from './user-detail.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ShippingRequestService {
  private router = inject(Router);
  private shipmentApi = inject(ShipmentApi);
  private alertService = inject(AlertService);
  private userService = inject(UserService);
  private userDetailService = inject(UserDetailService);
  private dialog = inject(MatDialog);
  private localeService = inject(LocaleService);

  fetchShipmentPreview(
    shippingPeriodId: string,
  ): Promise<Result<ShipmentPreview | ShipmentPreviewError, HttpErrorResponse>> {
    return firstValueFrom(
      this.shipmentApi
        .fetchShipmentPreview(shippingPeriodId)
        .pipe(map((shipmentPreview) => createSuccess(shipmentPreview))),
    ).catch((error: HttpErrorResponse) => {
      return createFailure(error);
    });
  }

  async showShippingPeriodDetailModal(shippingPeriodId: string) {
    await this.alertService.showLoader(this.localeService.translate('shared.loader.defaultMessage'));
    const shippingPeriod = await firstValueFrom(this.shipmentApi.fetchShippingPeriod(shippingPeriodId));
    this.alertService.dismissLoader();
    this.dialog
      .open(ShippingPeriodDetailModalComponent, {
        maxWidth: '600px',
        autoFocus: false,
        data: { shippingPeriod },
      })
      .afterClosed()
      .subscribe((data: { showPreview?: boolean }) => {
        if (data?.showPreview) {
          this.showShippingPeriodPreviewModal(shippingPeriodId);
        }
      });
  }

  async showShippingPeriodPreviewModal(shippingPeriodId: string) {
    await this.alertService.showLoader(this.localeService.translate('shared.loader.defaultMessage'));
    const prerequisites = await this.checkPrerequisites(shippingPeriodId);
    if (!prerequisites) {
      this.alertService.dismissLoader();
      return;
    }
    const shipmentPreview = await this.fetchShipmentPreview(shippingPeriodId);
    if (shipmentPreview.isFailure()) {
      this.alertService.dismissLoader();
      this.alertService.showErrorFromResponse(shipmentPreview.error.error);
      return;
    }
    this.alertService.dismissLoader();
    this.dialog
      .open(ShippingPeriodPreviewModalComponent, {
        maxWidth: '600px',
        autoFocus: false,
        data: { shipmentPreview: shipmentPreview.value },
      })
      .afterClosed()
      .subscribe((data: { showDeliveryRequest?: boolean }) => {
        if (data?.showDeliveryRequest) {
          this.showDeliveryRequestModal(shipmentPreview.value);
        }
      });
  }

  showDeliveryRequestModal(shipmentPreview: ShipmentPreview) {
    this.dialog
      .open(DeliveryRequestModalComponent, {
        maxWidth: '600px',
        autoFocus: false,
        data: { shipmentPreview },
      })
      .afterClosed()
      .subscribe((data: { shipmentId?: string; showPreview?: boolean }) => {
        if (data?.shipmentId) {
          this.router.navigateByUrl(`/exchange/history/${data.shipmentId}`);
        } else if (data?.showPreview) {
          this.showShippingPeriodPreviewModal(shipmentPreview.shippingPeriod.id);
        }
      });
  }

  showUpdateShipmentAddressModal(shipmentId: string) {
    const dialogClosed$ = this.dialog
      .open(DeliveryInfoEditModalComponent, {
        maxWidth: '600px',
        disableClose: true,
        autoFocus: false,
        data: { entityType: 'shipment', shipmentId },
      })
      .afterClosed()
      .pipe(
        take(1),
        map((data: { succeeded?: boolean }) => Boolean(data?.succeeded)),
      );
    return firstValueFrom(dialogClosed$);
  }

  private async noDeliveryInformation(shippingPeriodId: string) {
    const confirm = await this.alertService.showConfirmation({
      message: this.localeService.translate('shared.shippingRequest.noDeliveryInfoAlertMessage'),
      buttonText: this.localeService.translate('shared.shippingRequest.noDeliveryInfoAlertButtonText'),
    });
    if (confirm) {
      const result = await this.userDetailService.showDeliveryInfoEditModal();
      if (result) {
        this.showShippingPeriodPreviewModal(shippingPeriodId);
      }
    }
  }

  private async checkPrerequisites(shippingPeriodId: string): Promise<boolean> {
    // 配送住所が登録済みか
    const userDetail = await firstValueFrom(this.userService.fetchUserDetail());
    if (!userDetail?.regionCode) {
      this.noDeliveryInformation(shippingPeriodId);
      return false;
    }

    return true;
  }
}
