<div class="item" slLayout>
  <div class="container">
    <div class="thumbnail">
      <sl-image-grid class="thumbnail-image" [images]="images"></sl-image-grid>
    </div>
    <div class="item-info">
      <div class="shipment-number">
        <div class="shipment-number-label">{{ 'components.shipmentItem.shipmentNumber' | locale }}</div>
        <div class="shipment-number-value">{{ _shipment.shipmentNumber }}</div>
      </div>
      <div class="quantity">
        {{ 'components.shipmentItem.quantity' | locale: { quantity: _shipment.quantity | number } }}
      </div>
      <div class="status">{{ _shipment?.status | shipmentStatus }}</div>
    </div>
  </div>
  @if (showButton) {
    <div class="button-area">
      @if (_shipment?.addressChangeable) {
        <div class="button-area-text address-changeable">
          {{ 'components.shipmentItem.buttonLabelAddressChangeable' | locale }}
        </div>
      } @else {
        <div class="button-area-text">{{ 'components.shipmentItem.buttonLabel' | locale }}</div>
      }
    </div>
  }
</div>
