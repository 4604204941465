import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { ShipmentItemComponent } from '../../../components/shipment/shipment-item/shipment-item.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Shipment, ShipmentStatus } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ShipmentService } from '../../../services/shipment.service';
import { ExchangeRootPage } from '../exchange-root/exchange-root.page';

@Component({
  selector: 'page-exchange-shipped',
  templateUrl: './exchange-shipped.page.html',
  styleUrls: ['./exchange-shipped.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    LayoutComponent,
    InfiniteScrollDirective,
    ShipmentItemComponent,
    RouterLink,
    EmptyComponent,
    AsyncPipe,
    LocalePipe,
  ],
})
export class ExchangeShippedPage implements OnInit {
  shipments$: Observable<Shipment[]>;
  loading = true;
  loadingMore = false;
  readonly status: ShipmentStatus = 'shipped';

  private readonly exchangeRootPage = inject(ExchangeRootPage, { optional: true });
  private readonly shipmentService = inject(ShipmentService);

  constructor() {
    this.shipments$ = this.shipmentService.selectShipments(this.status);
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentService.fetchShipments(this.status, true).subscribe(() => {
      this.loading = false;
    });
    if (this.exchangeRootPage) {
      this.exchangeRootPage.setCurrentTab(this.status);
    }
  }

  onScroll() {
    if (!this.loadingMore && !this.shipmentService.shipmentPage[this.status].isComplete) {
      this.loadingMore = true;
      this.shipmentService.fetchShipments(this.status).subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
