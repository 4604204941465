import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { LayoutDirective } from '../../../directives/layout.directive';
import { ShipmentPreview } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ExchangeStepsComponent } from '../../exchange/exchange-steps/exchange-steps.component';
import { ShipmentLootsComponent } from '../../shipment/shipment-loots/shipment-loots.component';

export interface ShippingPeriodPreviewConfig {
  shipmentPreview: ShipmentPreview;
}

@Component({
  selector: 'sl-shipping-period-preview-modal',
  templateUrl: './shipping-period-preview-modal.component.html',
  styleUrls: ['./shipping-period-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ExchangeStepsComponent,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    LayoutDirective,
    ShipmentLootsComponent,
    MatDialogActions,
    MatButton,
    LocalePipe,
  ],
})
export class ShippingPeriodPreviewModalComponent implements OnInit {
  readonly data = inject<ShippingPeriodPreviewConfig>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject<MatDialogRef<ShippingPeriodPreviewModalComponent>>(MatDialogRef);

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  showDeliveryRequest() {
    this.dialogRef.close({ showDeliveryRequest: true });
  }
}
