<sl-exchange-steps mat-dialog-title [currentIndex]="3"></sl-exchange-steps>

<div class="page-container">
  <div class="content">{{ 'components.deliveryCompleteModal.title' | locale }}</div>

  <div class="shipment-id">{{ data?.shipmentId }}</div>

  <div class="note" [innerHTML]="'components.deliveryCompleteModal.note' | locale | safeHtml"></div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="backToStoreTop()">{{ 'shared.navigation.backToStoreTop' | locale }}</button>
  <button mat-flat-button color="primary" (click)="signIn()">
    {{ 'components.deliveryCompleteModal.signInButtonText' | locale }}
  </button>
</div>
