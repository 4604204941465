import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MatButton } from '@angular/material/button';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { MobilePaymentShipmentStatus } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ExchangeStepsComponent } from '../../exchange/exchange-steps/exchange-steps.component';

interface DeliveryCompleteModalData {
  status: MobilePaymentShipmentStatus;
  shipmentId: string;
}

@Component({
  selector: 'sl-delivery-complete-modal',
  templateUrl: './delivery-complete-modal.component.html',
  styleUrls: ['./delivery-complete-modal.component.scss'],
  standalone: true,
  imports: [ExchangeStepsComponent, MatDialogTitle, MatDialogActions, MatButton, LocalePipe, SafeHtmlPipe],
})
export class DeliveryCompleteModalComponent implements OnInit {
  readonly data = inject<DeliveryCompleteModalData>(MAT_DIALOG_DATA);

  private readonly router = inject(Router);
  private readonly dialogRef = inject<MatDialogRef<DeliveryCompleteModalComponent>>(MatDialogRef);

  ngOnInit(): void {}

  async backToStoreTop() {
    await this.router.navigate(['/exchange'], { replaceUrl: true });
    await this.router.navigate(['/']);
    this.dialogRef.close();
  }

  async signIn() {
    await this.router.navigate(['/exchange'], { replaceUrl: true });
    await this.router.navigate(['/mypage']);
    this.dialogRef.close();
  }

  dismiss() {
    this.dialogRef.close();
  }
}
