import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DecimalPipe } from '@angular/common';
import { LayoutDirective } from '../../../directives/layout.directive';
import { Image } from '../../../models/image';
import { Shipment } from '../../../models/shipment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { ShipmentStatusPipe } from '../../../pipes/shipment-status.pipe';
import { ImageGridComponent } from '../../image/image-grid/image-grid.component';

@Component({
  selector: 'sl-shipment-item',
  templateUrl: './shipment-item.component.html',
  styleUrls: ['./shipment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, ImageGridComponent, DecimalPipe, LocalePipe, ShipmentStatusPipe],
})
export class ShipmentItemComponent implements OnInit {
  _shipment: Shipment;
  images: Image[];
  @Input({ transform: coerceBooleanProperty }) showButton = false;
  @Input()
  set shipment(value: Shipment) {
    this._shipment = value;
    this.images = this._shipment.machines.map((machine) => machine?.mainImage);
  }

  constructor() {}

  ngOnInit() {}
}
